import Store from "../store"

const getPhotosCosts = () => Store.getters["common/getPhotoCosts"]

export const initCost = () => ({
    cost: 0,
    discount: 0,
    promo: 0,
    total: 0,
})

export const sumCosts = (value, ...args) => {
    args.forEach(detail => {
        value.cost += detail.cost
        value.discount += detail.discount
        value.promo += detail.promo
        value.total += detail.total
    })
    return value
}

/**
 * Calculate detailed cost by photo
 */
export const getDetailedPhotoCost = (photo, index, promoBalance = 0) => {
    const costs = getPhotosCosts()
    const cost = costs[Math.min(costs.length - 1, index)]
    const firstCost = costs[0]
    const promoDiscount = Math.min(promoBalance, cost)

    return {
        cost: firstCost,
        discount: firstCost - cost,
        promo: promoDiscount,
        total: cost - promoDiscount,
        self: photo,
    }
}

/**
 * Calculate detailed costs by photo collection
 */
export const getDetailedPhotoCosts = (photos, promoBalance = 0) => {
    const result = []
    photos.forEach((photo, i) => {
        const details = getDetailedPhotoCost(photo, i, promoBalance)
        result.push(details)
        promoBalance -= details.promo
    })

    return {
        details: result,
        availablePromo: promoBalance,
    }
}

export const getDetailedPhotoreportCost = (photoreport, promoBalance = 0) => {
    const details = Object.assign(initCost(), { self: photoreport })

    if (photoreport.Cost) {
        const cost = parseFloat(photoreport.Cost)
        details.cost = cost
        details.promo = Math.min(promoBalance, cost)
        details.total = details.cost - details.promo
        return {
            details,
            photoDetails: [],
            availablePromo: promoBalance - details.promo,
        }
    } else {
        const photoCosts = getDetailedPhotoCosts(photoreport.Photos, promoBalance)

        return {
            details: photoCosts.details.reduce((res, cost) => sumCosts(res, cost), details),
            photoDetails: photoCosts,
            availablePromo: photoCosts.availablePromo,
        }
    }
}
