<template>
    <v-container style="background-color: #fff" fluid>
        <div v-if="loading" class="d-flex justify-center mt-8 mb-8 flex-column align-center">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
            <p class="headline mt-4">{{ $t(`messages['loading data']`) }}</p>
        </div>
        <div v-show="!loading">
            <div class="d-flex align-center justify-space-between my-4">
                <div class="body-1 d-inline-flex" @click="onBackToPlace()"
                     style="cursor: pointer; color: rgba(0, 0, 0, 0.54)">
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    {{ getPlaceName }}
                </div>
                <div>
                    <v-btn text :href="`/session/${getPhotoreport.ID}` | webLink" target="_blank" small color="primary">
                        {{ $t(`actions['open site']`) }}
                    </v-btn>
                </div>
            </div>
            <div class="d-flex justify-space-between align-center">
                <div class="headline font-weight-bold">
                    {{ $t(`toolbar.session`, [getName]) }}
                    <span v-if="getPhotoreport.Hidden">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon color="rm -rf node_modules && rm -rf dist && npm cache clean --force && npm install#000"
                                        v-on="on">mdi-lock</v-icon>
                            </template>
                            <span>{{ $t(`labels['hidden session']`) }}</span>
                        </v-tooltip>
                    </span>
                </div>
                <div class="d-flex">
                    <div class="pr-2 page-session__total">
                        {{ $t(`rudiments['session cost']`) }}
                        {{ $t(`rudiments['session cost']`) }} {{getPhotoreportPrice.details.total | currency}}<br/>
                        <span v-if="isCalculatedCost">{{ $t(`messages['calculated cost']`) }}</span>
                        <span v-if="!isCalculatedCost">{{ $t(`messages['set by employee']`) }}</span>
                    </div>
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-icon color="#000" v-on="{...tooltip, ...menu}">mdi-cog</v-icon>
                                </template>
                                <span>{{ $t(`menu['settings']`) }}</span>
                            </v-tooltip>
                        </template>
                        <v-list>
                            <v-list-item v-for="(item, index) in menuItems" :key="index" @click="item.action">
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
            <h3 class="my-4 heading font-weight-medium">
                {{ getPhotos.length }} {{ $tc('plural.photos', getPhotos.length) }}
            </h3>
            <div class="session-page__overview">
                <div class="session-page__feature py-1 ">
                    {{ $t(`rudiments['NML']`) }}:
                    <span v-if="getFeatures.ClientName">{{ getFeatures.ClientName }}</span>
                    <span v-else>{{ $t(`placeholders['not specified']`) }}</span>
                </div>
                <div class="session-page__feature py-1">
                    {{ $t(`rudiments['checkout date']`, [getCheckOutDate]) }}
                </div>
                <div class="session-page__feature py-1">
                    {{ $t(`rudiments['room number']`) }}:
                    <span v-if="getFeatures.RoomNumber">{{ getFeatures.RoomNumber }}</span>
                    <span v-else>{{ $t(`placeholders['not specified']`) }}</span>
                </div>
                <div class="session-page__feature py-1">
                    {{ $t(`rudiments['clients email']`) }}
                    <span v-if="getEmails.length">{{ getEmails }}</span>
                    <span v-else>{{ $t(`placeholders['not specified']`) }}</span>
                </div>
                <div class="session-page__feature py-1">
                    {{ $t(`rudiments['clients phone']`) }}
                    <span v-if="getPhones.length">{{ getPhones }}</span>
                    <span v-else>{{ $t(`placeholders['not specified']`) }}</span>
                </div>
                <div class="session-page__feature py-1">
                    {{ $t(`labels['hidden session']`) }}:
                    <span v-if="getPhotoreport.Hidden">{{ $t(`placeholders['yes']`) }}</span>
                    <span v-else>{{ $t(`placeholders['no']`) }}</span>
                </div>
                <div class="session-page__feature py-1">
                    {{ $t(`rudiments['prepaid']`) }}
                    <span v-if="getPhotoreport.Prepaid">{{ $t(`placeholders['yes']`) }}</span>
                    <span v-else>{{ $t(`placeholders['no']`) }}</span>
                </div>
                <div class="session-page__feature py-1">
                    {{ $t(`rudiments['bulk']`) }}
                    <span v-if="getPhotoreport.BulkOnly">{{ $t(`placeholders['yes']`) }}</span>
                    <span v-else>{{ $t(`placeholders['no']`) }}</span>
                </div>
            </div>

            <div class="mt-2">
                <photo-actions
                    v-if="canInteract"
                    :place-id="getPhotoreport.PlaceID"
                    :photoreport-id="getPhotoreport.ID"
                    :photos="getPhotos"
                    :selected-photos.sync="selected"
                    allow-copy
                    @reload="loadPhotoreport()"/>

                <grid-photos :selected.sync="selected" :photos="getPhotos" :readonly="!canInteract">
                    <template slot="preview-panel" slot-scope="props">
                        <lightbox-panel v-if="props.photo" :photo="props.photo.self"/>
                    </template>
                </grid-photos>
            </div>


            <photoreport-form-modal @submit="editPhotoreport()" v-model="photoreportModel"
                                    :visible.sync="visiblePhotoreportModal">
                <template slot="title">{{ $t(`headers['edit photoreport']`) }}</template>
            </photoreport-form-modal>

            <photoreport-settings-modal :visible.sync="visibleSettings" v-model="settingsModel"
                                        @submit="onSubmitChangeSettings()"/>
        </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import GridPhotos from "../components/photos/GridPhotos"
import LightboxPanel from "../components/photogroups/LightboxPanel"
import PhotoreportSettingsModal from "../components/modal/PhotoreportSettingsModal"
import PhotoreportFormModal from "../components/modal/PhotoreportFormModal"
import PhotoActions from "../components/photos/PhotoActions"
import Adapters from "../util/adapters"
import * as Format from "../util/format"
import * as Price from "../util/price"
import unobserve from "../util/unobserve"

const defaultPhotoreport = () => ({
    ID: "",
    Name: {},
    Features: {
        ClientName: "",
        Emails: [],
        Phones: [],
        CheckOutDate: null,
        RoomNumber: null,
    },
})

const defaultSettings = () => ({
    BulkOnly: false,
    Hidden: false,
    Cost: "",
    Prepaid: "",
})

export default {
    components: {
        GridPhotos,
        LightboxPanel,
        PhotoActions,
        PhotoreportFormModal,
        PhotoreportSettingsModal,
    },
    data() {
        return {
            selected: [],
            loading: false,
            selectedPhotoreport: null,
            visibleSelectPhotoreport: false,
            visibleSettings: false,
            settingsModel: defaultSettings(),
            photoreportModel: defaultPhotoreport(),
            visiblePhotoreportModal: false,
            moveFlag: false,
            menuItems: [
                {
                    text: this.$t(`menu['session parameters']`),
                    action: () => this.onClickEditPhotoreport(),
                },
                {
                    text: this.$t(`menu['session settings']`),
                    action: () => this.onClickSettings(),
                },
                {
                    text: this.$t(`menu['delete session']`),
                    action: () => this.onClickDeletePhotoreport(),
                },
            ],
        }
    },
    head() {
        return {
            toolbar: this.$t("toolbar.session", [this.getName]),
            title: this.$t("titles.session"),
        }
    },
    fetch({ store, redirect, params }) {
        if (!store.getters["common/isAuthorized"]) {
            return redirect("/login")
        }
        return store.dispatch("photoreport/storePhotoreport", params.id)
    },

    computed: {
        ...mapGetters("photoreport", ["getPhotoreport"]),
        ...mapGetters("common", ["getUser"]),
        getPlaceName() {
            return Format.getName(this.getPhotoreport.Place.Name)
        },

        getPhotoreportFilter() {
            return { PlaceID: this.getPhotoreport.PlaceID }
        },

        getCheckOutDate() {
            return (this.getFeatures.CheckOutDate && this.getFeatures.CheckOutDate !== "0")
                ? Format.formatDateTime(this.getFeatures.CheckOutDate * 1000, "DD.MM.YYYY")
                : this.$t(`placeholders['not specified']`)
        },

        getPhotos() {
            const photos = Adapters.photos(this.getPhotoreport.Photos)

            return photos.sort((a, b) => {
                return new Date(a.self.Metadata.EXIF.Time * 1000) < new Date(b.self.Metadata.EXIF.Time * 1000) ? 1 : -1
            })
        },

        getFeatures() {
            return this.getPhotoreport.Features
        },

        getEmails() {
            if (!this.getFeatures.Emails.length) {
                return this.$t(`placeholders['not specified']`)
            }
            return this.getFeatures.Emails.filter(f => f !== "").join(", ")
        },

        getName() {
            return Format.getName(this.getPhotoreport.Name)
        },

        getPhones() {
            if (!this.getFeatures.Phones.length) {
                return this.$t(`placeholders['not specified']`)
            }
            return this.getFeatures.Phones.filter(f => f !== "").join(", ")
        },

        getPhotoreportPrice() {
            return Price.getDetailedPhotoreportCost(this.getPhotoreport)
        },

        isCalculatedCost() {
            return this.getPhotoreport === ""
        },

        isAdmin() {
            return this.getPhotoreport.Place.Admins.includes(this.getUser.ID) || this.getUser.JS
        },

        isPhotographer() {
            return this.getPhotoreport.Place.Photographers.includes(this.getUser.ID)
        },

        canInteract() {
            return this.isAdmin || this.isPhotographer
        },
    },
    methods: {
        ...mapActions({
            updatePhotoreport: "photoreport/update",
            storePhotoreport: "photoreport/storePhotoreport",
            removePhotoreport: "photoreport/remove",
            deletePhotos: "photoreport/deletePhotos",
            updateSettings: "photoreport/updateSettings",
            movePhotos: "photoreport/move",
            copyPhotos: "photoreport/addPhotos",
            removePhones: "photoreport/removePhones",
            removeEmails: "photoreport/removeEmails",
        }),

        loadPhotoreport() {
            return this.storePhotoreport(this.getPhotoreport.ID)
        },

        onBackToPlace() {
            this.$router.push(`/places/${this.getPhotoreport.PlaceID}#photos`)
        },

        onClickDeletePhotoreport() {
            this.$dialog.confirm({
                title: this.$t(`rudiments['sure']`),
                text:  this.$t(`rudiments['delete session']`, [this.getName]),
            }).then(() => {
                this.removePhotoreport(this.getPhotoreport.ID)
                this.$router.push(`/places/${this.getPhotoreport.PlaceID}#photos`)
            })
        },

        editPhotoreport() {
            this.visiblePhotoreportModal = false
            const id = this.getPhotoreport.ID
            const removeFeatures = this.getRemoveFeatures()
            let hasError = false

            this.$dialog.loading({ text: this.$t(`messages['settings updates']`) }).then(dialog => {
                Promise.all([
                    this.updatePhotoreport(this.photoreportModel),
                    this.removePhones({ id, phones: removeFeatures.phones }),
                    this.removeEmails({ id, phones: removeFeatures.emails }),
                ]).then(() => this.loadPhotoreport())
                    .catch(() => {
                        hasError = true
                        return this.loadPhotoreport()
                    })
                    .finally(() => {
                        dialog.close()
                        if (hasError) {
                            this.$dialog.message.error(this.$t(`errors['photoreport update error']`))
                        } else {
                            this.$dialog.message.success(this.$t(`messages['settings updated']`))
                        }
                    })
            })
        },


        getRemoveFeatures() {
            const features = this.photoreportModel.Features
            const oldFeatures = this.getPhotoreport.Features

            const newPhones = features.Phones.filter(p => !!p)
            const newEmails = features.Emails.filter(p => !!p)

            const oldPhones = oldFeatures.Phones.filter(p => !!p)
            const oldEmails = oldFeatures.Emails.filter(p => !!p)

            return {
                phones: oldPhones.filter(phone => !newPhones.includes(phone)),
                emails: oldEmails.filter(email => !newEmails.includes(email)),
            }
        },

        onClickEditPhotoreport() {
            this.photoreportModel = unobserve({
                ID: this.getPhotoreport.ID,
                Features: this.getPhotoreport.Features,
                Name: this.getPhotoreport.Name,
            })
            this.visiblePhotoreportModal = true
        },

        onClickSettings() {
            const { ID, BulkOnly, Prepaid, Cost, Hidden } = this.getPhotoreport
            this.settingsModel = { ID, BulkOnly, Prepaid, Cost, Hidden }
            this.visibleSettings = true
        },

        onSubmitChangeSettings() {
            this.visibleSettings = false
            let loadingDialog = null
            this.$dialog.loading({ text: this.$t(`messages['settings applies']`) }).then(d => loadingDialog = d)
            return this.updateSettings(this.settingsModel)
                .then(() => {
                    this.$dialog.message.success(this.$t(`messages['settings updated']`))
                })
                .catch(() => {
                    this.$dialog.message.success(this.$t(`errors['settings update error']`))
                })
                .finally(() => {
                    loadingDialog.close()
                    this.loadPhotoreport()
                })
        },
    },
}
</script>
<style lang="stylus">
    .page-session__total {
        line-height 14px

        span {
            font-size 12px
        }
    }
</style>
