<script>
import CompoundGridPhotos from "storemoodify/lib/CompoundGridPhotos"

export default {
    functional: true,
    render(createElement, context) {
        const scopedSlots = Object.assign({}, context.scopedSlots)
        const ctx = { scopedSlots, on: context.listeners }

        return createElement(CompoundGridPhotos, Object.assign({}, context, ctx))
    },
}
</script>
