<template>
    <v-dialog persistent max-width="600px" v-model="visibleModal">
        <v-card>
            <v-form ref="form" v-model="isValid" @submit.prevent="onSubmit()">
                <v-card-title>
                    <span class="headline">
                        {{ $t(`headers['report settings']`) }}
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div>
                            <v-switch v-model="model.Hidden" class="mb-0 pb-0" inset :label="$t(`labels['hidden']`)"
                                      :messages="getHiddenMessage"/>
                        </div>
                        <div>
                            <v-switch v-model="model.BulkOnly" inset :label="$t(`labels['bulk']`)"
                                      :messages="getBulkMessage"/>
                        </div>
                        <div>
                            <v-switch v-model="model.Prepaid" inset :label="$t(`labels['prepaid']`)"
                                      :messages="getPrepaidMessage"/>
                        </div>
                        <v-text-field :messages="getCostMessage" :disabled="!model.BulkOnly" v-model="model.Cost" class="mt-6" :rules="[costRule]" outlined :label="$t(`labels['session cost']`)"/>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="error" outlined @click="onClose()">{{ $t("actions.cancel") }}</v-btn>
                    <v-btn outlined ripple color="blue-grey darken-1" type="submit">{{ $t("actions.save") }}</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import ModalFormMixin from "./ModalFormMixin"

export default {
    mixins: [ModalFormMixin],

    data() {
        return {
            costRule: v => this.model.BulkOnly && (!!parseInt(v) > 0 || this.$t(`errors['not less']`, [0]))
        }
    },

    watch: {
        "model.BulkOnly"(val) {
            if (val === true) {
                this.model.Prepaid = false
            }
        },
        "model.Prepaid"(val) {
            if (val === true) {
                this.model.BulkOnly = false
                this.model.Hidden = false
            }
        },
    },
    computed: {
        getHiddenMessage() {
            return this.$t(`messages['no search']`)
        },
        getBulkMessage() {
            return this.$t(`messages['bulk only']`)
        },
        getPrepaidMessage() {
            return this.$t(`messages['prepaid session']`)
        },
        getCostMessage(){
            return this.$t(`messages['session cost']`)
        }
    },
    methods: {
        onClose() {
            this.close()
        },
    },
}
</script>
